@tailwind base;
@tailwind components;
@tailwind utilities;

.madimi-one-regular {
	font-family: "Madimi One", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.inter {
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
	font-variation-settings:
		"slnt"0;
}

.inter-700 {
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	font-weight: 700;
	font-style: normal;
	font-variation-settings:
		"slnt"0;
}

.honk {
	font-family: "Honk", system-ui;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
	font-variation-settings:
		"slnt"0;
}

.honk-700 {
	font-family: "Honk", system-ui;
	font-optical-sizing: auto;
	font-weight: 700;
	font-style: normal;
	font-variation-settings:
		"slnt"0;
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #0f172a;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

body, ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
